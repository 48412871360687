<!--
 * @Description: 类南华顶部主题
 * @Author: luocheng
 * @Date: 2022-07-13 15:06:35
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-09-06 14:15:29
-->
<template>
  <div class="theme-one" v-if="systemConfig">
    <section class="logo">
      <img :src="systemConfig.little_logo || systemConfig.logo" :alt="systemConfig.name">
      <h3 class="title">{{ systemConfig.sortName || systemConfig.name }}</h3>
    </section>
		<template v-if="!isUpdatePwd">
			<section class="fix-actions">
				<!-- 返回二屏 -->
				<a href="javascript:;" class="back-second" @click="onHome" v-if="systemConfig.need_second">
					<img src="@/assets/images/back-home.png" alt="首页">
				</a>
				<!-- 应用中心 -->
				<a href="javascript:;"	
            :class="{
							'active': showapps
						}" 
            class="app-center" @click="onAPPCenter" v-if="systemConfig.need_app_center">
					<i class="iconfont iconc-myapp"></i>
					<!-- <span class="label" v-if="currentApp">{{ currentApp.name }}</span> -->
					<!-- <i class="iconfont iconxiangyoujiantou2"></i> -->
				</a>
			</section>
			<!-- 模块 -->
			<!-- eslint-disable -->
			<ul class="nav-list">
				<template v-if="!isUpdatePwd">
					<li
						class="nav-item"
						v-for="item in topNav"
						:key="item.key"
						:class="{
							'active': currentMenu && ( +currentMenu.id === +item.id || currentMenu.menu_path === item.menu_path)&& !showapps
						}"
						@click="onTopNav(2, item, true)"
						v-if="!item.is_hide
							&& judgingArchiAPermission(item.archi_limit, item.permissions, true)
							&& judgingCustomLogic(item.custom_logics)
						"
					>
						<template v-if="item.icon">
							<i :class="['element-iconfont', item.icon]" v-if="item.icon.indexOf('el-icon') === 0"></i>
							<svg aria-hidden="true" class="iconfont-svg" v-else>
								<use :xlink:href="'#' + item.icon" />
							</svg>
						</template>
						<span class="label">{{ item.name }}</span>
					</li>
				</template>
			</ul>
			<!-- 附加信息 -->
			<section class="additional-content">
				<!-- 五方 -->
				<Cooperation v-if="needFiveParties"></Cooperation>
				<!-- 架构 -->
				<SelectArchi :reload="true" v-if="needArchiInfo"></SelectArchi>
				<!-- APP下载二维码 -->
				<appQrcode v-if="needAppQrcode"></appQrcode>
				<!-- 个人信息 -->
				<UserActions></UserActions>
			</section>
		</template>
    <Apphome v-show="showAppCenter" @close="onCloseAppHome"></Apphome>
  </div>
</template>

<script>
import mixin from './mixin';
import cardMixin from '@/custom-component/business/cardMixin';

export default {
  name: 'ThemeOne',
	mixins: [cardMixin, mixin],
}
</script>

<style lang="less" scoped>
@height: 48px;
.theme-one{
  display: flex;
	min-height: @height;
	min-width: 860px;
	width: 100%;
	box-sizing: border-box;
	padding: 0 16px;
	background: #4589ff;
	user-select: none;
  .logo {
		box-sizing: border-box;
		padding: 8px 0;
		display: flex;
		min-width: 32px;
		img {
			display: block;
			height: 32px;
			// min-width: 32px;
			object-fit: cover;
			border-radius: 6px;
			// background: #fff;
		}
		.title {
			box-sizing: border-box;
			padding: 4px 16px;
			font-size: 16px;
			font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 500;
			text-align: left;
			color: #ffffff;
			line-height: 24px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		span {
			display: block;
			height: 18px;
			margin-top: 7px;
			width: 1px;
			background: #fff;
		}
	}
	.fix-actions{
		display: flex;
		.back-second{
			box-sizing: border-box;
			padding: 12px 15px;
			&:hover{
				color: #fff;
				background: rgb(77, 134, 232);
				opacity: 1;
			}
			img{
				display: block;
				height: 20px;
				margin: 2px;
			}
		}
		.app-center {
			box-sizing: border-box;
			padding: 12px 15px;
			// padding-right: 16px;
			display: flex;
			cursor: pointer;
      &.active {
				color: #fff;
				opacity: 1;
				background: rgba(255, 255, 255, 0.2);
			}
			&:hover{
				color: #fff;
				background: rgb(77, 134, 232);
				opacity: 1;
			}
			img {
				margin-top: 1px;
				height: 22px;
				width: 22px;
				margin-right: 8px;
			}
			.label {
				line-height: 24px;
				box-sizing: border-box;
				padding: 0 15px;
				color: #fff;
				font-size: 16px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
			.iconfont {
				font-size: 16px;
				line-height: 24px;
				color: #fff;
			}
		}
	}
	.nav-list {
		flex: 1;
		display: flex;
		min-height: @height;
		overflow: hidden;
		overflow-x: auto;
		.nav-item {
			width: auto;
			// min-width: 112px;
			min-height: @height;
			box-sizing: border-box;
			padding: 12px 24px;
			text-align: center;
			opacity: 0.5;
			font-size: 16px;
			font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 500;
			color: #fff;
			opacity: 0.5;
			line-height: 24px;
			background: transparent;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			flex-shrink: 0;
			&:hover {
				color: #fff;
				background: rgb(77, 134, 232);
				opacity: 1;
			}
			&.active {
				color: #fff;
				opacity: 1;
				background: rgba(255, 255, 255, 0.2);
			}
			.element-iconfont{
				margin-right: 5px;
			}
			.label{
				height: 100%;
			}
		}
	}
	.additional-content{
		display: flex;
	}
}
</style>