import { mapState } from 'vuex';
import { judgingArchiAPermission, judgingCustomLogic } from '@/utils/tools';
import { isBoolean } from 'xe-utils';
import { dataInterface } from '@/apis/data';

export default {
  data() {
    return {
      activeObject: null,
      menuList: []
    }
  },
  computed: {
    ...mapState([
      'applicationInfo',
      'currentMenu',
      'showMenuList',
      'appList',
      'systemConfig'
    ]),
    // 图标
    toggleIcon() {
      return this.showMenuList ? ' iconfold_' : 'iconfold';
    },
    themeClassName() {
      const { theme_type = 1 } = this.systemConfig || {};
      if (theme_type === 2) {
        return 'menu-part-two';
      }
      return 'menu-part-one';
    }
  },
  created() {
    this.archiType = this.$GetTargetArchi('archiType');
    let currentPart = sessionStorage.getItem('currentPart');
    if (currentPart) {
      currentPart = JSON.parse(currentPart);
      if (this.globalConfig.find(ele => ele[this.idUUID] === currentPart[this.idUUID])) {
        this.onMenu(currentPart, true);
      }
    }
    this.setCurrentPart();
  },
  watch: {
    $route: {
      handler() {
        this.setCurrentPart();
      },
      deep: true,
      immediate: true
    },
    currentMenu: {
      handler() {
        if (!this.currentMenu) {
          const menuData = this.findCurrentMenu();
          if (menuData) {
            this.$store.commit('setCurrentMenu', {
              level: 2,
              menuData
            })
          }
        } else {
          const children = this.currentMenu?.children_list || this.currentMenu?.app_orign_data || [];
          this.menuList = this.getMenuByLevel(3, children, []);
        }
        this.setCurrentPart();
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    judgingArchiAPermission,
    judgingCustomLogic,
    /**
     * @desc: 点击节点
     * @param {Object} menu 选中的菜单
     */
    async onMenu(menuData) {
      this.activeObject = menuData;
      this.$store.commit('setCurrentMenu', {
        level: 3,
        menuData
      });
      // 功能组展开下级
      const { menu_type, link, link_type, menu_path, page_uuid } = menuData;
      this.onToggleNav(!!(menu_type === 2));
      // 进入应用历史记录
      if ([0, 1, 3, 4].includes(menu_type)) {
        this.$store.commit('setAppNavHistory', {
          origin: 13,
          history: menuData
        });
      }
      if (menu_type === 0) {
        // 页面
        if (this.$route.path === menu_path) return;
        const query = {};
        if (menu_path &&  menu_path.toString().includes('?')) {
          const str = menu_path.split('?')?.[1];
          const queryList = str.toString().split('&');
          queryList.forEach(ele => {
            const eleArr = ele.toString()?.split('=')
            this.$set(query, eleArr?.[0] || '', eleArr?.[1] || '')
          })
        }
        this.$router.push({
          path: menu_path.split('?')?.[0],
          query: {
            ...query,
            // pageId: pageObj.page_id, 暂时移除页面ID
            pageUUID: page_uuid
          }
        });
      } else if (menu_type === 1) {
        // 跳转链接
        let linkUrl = link;
        if (link === 'DATAVIEW') {
          // 大屏
          linkUrl = await this.getDataViewUrl();
        }
        if (!linkUrl) {
          this.$message.error('出错了，请重试!');
          return false;
        }
        if (link_type === '_blank') {
          window.open(linkUrl);
        } else if (link_type === '_self') {
          window.location.href = linkUrl;
        } else {
          window.open(linkUrl);
        }
      } else if (menu_type === 2) {
        // 分组
        // this.$store.commit('setCurrentMenuGroup', menuData);
      } else if (menu_type === 3) {
        // 暂时弃用
        if (this.$route.path === menu_path) return;
        // console.log('微前端页面', pageObj, '------', itemPath);
        // 微前端页面
        this.$router.push({
          path: menu_path,
          query: {
            type: 'micro'
          }
        });
      } else if (menu_type === 4) {
        // console.log('仅跳转页面')
        if (this.$route.path === menu_path) return;
        this.$router.push({
          path: menu_path
        });
      }
    },
    /**
     * @desc: 获取大屏默认绑定
     */
    getDataViewUrl() {
      const loading = this.$loading();
      return new Promise((resolve, reject) => {
        dataInterface({}, '/api/dataview/getDefault', 'GET').then((res) => {
          if (res.status === 200) {
            resolve(`${process.env.VUE_APP_BASE_URL}pc/dataview/show/${res.data.data}`)
          }
          loading.close();
        }).catch(err => {
          console.log(err, '-----------');
          reject('');
          loading.close();
        })
      });
    },
    /**
     * @desc: 切换菜单
     */
    onToggleNav(boolean) {
      this.$store.commit('toggleMenuListShow', isBoolean(boolean) ? boolean : undefined);
    },
    /**
     * @desc: 根据指定层级获取菜单列表
     * @param {Number} level 层级
     * @param {Array} list 列表
     * @param {Array} result 菜单列表
     */
    getMenuByLevel(level, list, result = []) {
      if (isNaN(level) || level < 2) {
        return [];
      }
      if (!list || !Array.isArray(list)) {
        return [];
      }
      list.forEach(ele => {
        if (ele.level === level && (ele.parent_id === this.currentMenu.app_origin_id || ele.parent_id === this.currentMenu.id)) {
          result.push(ele);
        }
        if (ele.level < level) {
          const children_list = ele.children_list;
          if (children_list && Array.isArray(children_list) && children_list.length) {
            result = this.getMenuByLevel(level, children_list, result)
          }
        }
      });
      return result;
    },
    /**
     * @desc: 查找当前所处的菜单组
     */
    findCurrentMenu() {
      if (!this.appList) return null;
      const path = this.$route.path;
      for (let i = 0, len = this.appList.length; i < len; i++) {
        const { app_orign_data } = this.appList[i];
        const menuList = app_orign_data?.[0]?.children_list || [];
        for (let j = 0; j < menuList.length; j++) {
          // 顶部
          const item = menuList[j];
          const level2Children = item.children_list || [];
          // 第三级别
          if (level2Children?.find(ele => ele.menu_path === path)) {
            return item;
          }
          for (let k = 0; k < level2Children.length; k++) {
            if (level2Children[k]?.menu_type === 2 && level2Children[k]?.children_list.length) {
              // 第四级
              const level3Children = level2Children[k].children_list || [];
              if (level3Children?.find(ele => ele.menu_path === path)) {
                return item;
              }
              // 第五级别
              for (let l = 0; l < level3Children.length; l++) {
                if (level3Children[l].menu_type === 2 && level3Children[l].children_list.length) {
                  const level4Children = level3Children[l].children_list || [];
                  if (level4Children.find(ele => ele.menu_path === path)) {
                    return item;
                  }
                }
              }
            }
          }
        }
        return null;
      }
    },
    /**
     * @desc: 根据当前的路由获取是顶部二级菜单
     */
    setCurrentPart() {
      const path = this.$route.path;
      if(this.isCurrentActive(this.activeObject)){
        return;
      }
      for (let i = 0, len = this.menuList.length; i < len; i++) {
        const { menu_path, children_list = [] } = this.menuList[i];
        if (menu_path.split('?')?.[0] === path) {
          this.onMenu(this.menuList[i])
          return;
        }
        // 四五级
        for (let j = 0, childrenLen = children_list.length; j < childrenLen; j++) {
          const child = children_list[j];
          if (child.menu_path.split('?')?.[0] === path || child.children_list.find(ele => ele.menu_path === path)) {
            this.onMenu(this.menuList[i])
            return;
          }
        }
      }
    },
    /**
     * @desc: 是否为当前选中路由
     * @param {Object} menu
     */
      isCurrentActive(menu) {
        if (!menu) return false;
        const { child_path_list, menu_path } = menu;
        if (this.currentPath === menu_path) {
          return true;
        }
        if (child_path_list && Array.isArray(child_path_list)) {
          for (let i = 0; i < child_path_list.length; i++) {
            if (window.location.href.indexOf(child_path_list[i]) > -1) {
              return true;
            }
          }
        }
        return false;
      },
    /**
     * @desc: 右击菜单
     * @param {Object} data
     * @param {Object} e
     */
    onContextMenu(data, e) {
      e.stopPropagation();
      e.preventDefault();
      console.log(data, '---右击菜单--', e)
      // const top = e.pageY;
      // const left = e.pageX;
      // this.$store.commit('showCustomMenu', {
      // 	position: {
      // 		left,
      // 		top
      // 	},
      // 	data
      // });
    },
    /**
     * @desc: 滚动
     */
    onScroll() {
      this.$store.commit('hideCustomMenu');
    }
  }
}