<!--
 * @Description: 左侧主要菜单
 * @Author: luocheng
 * @Date: 2021-10-30 14:39:36
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-10-11 09:17:00
-->
<template>
	<div class="menu-part 111" :class="[themeClassName]">
		<i
			class="icon-box"
			:class="['iconfont', toggleIcon]"
			@click="onToggleNav(null)"
		></i>
		<div
			class="nav-one-list"
			style="height: calc(100vh-80px)"
			@scroll="onScroll"
		>
			<!-- eslint-disable -->
			<div
				class="menu-item"
				v-for="item in menuList"
				:key="item.id"
				:class="{
					'active': activeObject && item.id === activeObject.id
				}"
				v-if="judgingArchiAPermission(item.archi_limit, item.permissions) && !item.is_hide 
				&& judgingCustomLogic(item.custom_logics)
				"
				@click="onMenu(item)"
				@contextmenu="onContextMenu(item, $event)"
			>
				<i v-if="item.icon && item.icon.toString().indexOf('el-icon') === 0"
					:class="item.icon || 'el-icon-warning-outline'">
				</i>
				<i v-else :class="['iconfont', item.icon || 'iconyeqian']"></i>
				<strong>{{ item.name }}</strong>
				<div class="cover-bg"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { judgingArchiAPermission, judgingCustomLogic } from '@/utils/tools';
import { dataInterface } from '@/apis/data';

export default {
	name: 'MenuPart',
  data() {
    return {
      activeObject: null,
			menuList: []
    }
  },
  computed: {
		...mapState([
			'applicationInfo',
			'currentMenu',
			'showMenuList',
			'appList',
      'systemConfig'
		]),
		// 图标
		toggleIcon() {
			return this.showMenuList ? ' iconfold_' : 'iconfold';
		},
    themeClassName() {
      const { theme_type = 1 } = this.systemConfig || {};
      if (theme_type === 2) {
        return 'menu-part-two';
      }
      return 'menu-part-one';
    }
	},
  created() {
		this.archiType = this.$GetTargetArchi('archiType');
		let currentPart = sessionStorage.getItem('currentPart');
		if (currentPart) {
			currentPart = JSON.parse(currentPart);
			if (this.globalConfig.find(ele => ele[this.idUUID] === currentPart[this.idUUID])) {
				this.onMenu(currentPart, true);
			}
		}
		this.setCurrentPart();
  },
	watch: {
		$route: {
			handler() {
				this.setCurrentPart();
			},
			deep: true,
			immediate: true
		}, 
		currentMenu: {
			handler() {
				if (!this.currentMenu) {
					const menuData = this.findCurrentMenu();
					if (menuData) {
						this.$store.commit('setCurrentMenu', {
							level: 2,
							menuData
						})
					}
				} else {
					const children = this.currentMenu?.children_list || this.currentMenu?.app_orign_data || [];
					this.menuList = this.getMenuByLevel(3, children, []);
				}
				this.setCurrentPart();
			},
			immediate: true,
			deep: true
		}
	},
  methods: {
		judgingArchiAPermission,
		judgingCustomLogic,
		/**
		 * @desc: 点击节点
		 * @param {Object} menu 选中的菜单
		 */
		async onMenu(menuData) {
			this.activeObject = menuData;
			this.$store.commit('setCurrentMenu', {
				level: 3,
				menuData
			});
			// 功能组展开下级
			const { menu_type, link, link_type, menu_path, page_uuid } = menuData;
			this.onToggleNav(!!(menu_type === 2));
			// 进入应用历史记录
			if ([0, 1, 3, 4].includes(menu_type)) {
				this.$store.commit('setAppNavHistory', {
          origin: 12,
          history: menuData
        });
			}
			if (menu_type === 0) {
				// 页面
				if (this.$route.path === menu_path) return;
				this.$router.push({
					path: menu_path,
					query: {
						// pageId: pageObj.page_id, 暂时移除页面ID
						pageUUID: page_uuid
					}
				});
			} else if (menu_type === 1) {
				// 跳转链接
				let linkUrl = link;
				if (link === 'DATAVIEW') {
					// 大屏
					linkUrl = await this.getDataViewUrl();
				}
				if (!linkUrl) {
					this.$message.error('出错了，请重试!');
					return false;
				}
				if (link_type === '_blank') {
					window.open(linkUrl);
				} else if (link_type === '_self') {
					window.location.href = linkUrl;
				} else {
					window.open(linkUrl);
				}
			} else if (menu_type === 3) {
				// 暂时弃用
				if (this.$route.path === menu_path) return;
				// console.log('微前端页面', pageObj, '------', itemPath);
				// 微前端页面
				this.$router.push({
					path: menu_path,
					query: {
						type: 'micro'
					}
				});
			} else if (menu_type === 4) {
				// console.log('仅跳转页面')
				if (this.$route.path === menu_path) return;
				this.$router.push({
					path: menu_path
				});
			}
		},
		/**
		 * @desc: 获取大屏默认绑定
		 */
		getDataViewUrl() {
			const loading = this.$loading();
			return new Promise((resolve, reject) => {
				dataInterface({}, '/api/dataview/getDefault', 'GET').then((res) => {
					if (res.status === 200) {
						resolve(`${process.env.VUE_APP_BASE_URL}pc/dataview/show/${res.data.data}`)
					}
					loading.close();
				}).catch(err => {
					console.log(err, '-----------');
					reject('');
					loading.close();
				})
			});
		},
		/**
		 * @desc: 切换菜单
		 */
		onToggleNav(boolean) {
			this.$store.commit(
				'toggleMenuListShow',
				Object.prototype.toString.call(boolean) === '[object Boolean]' ? boolean : undefined
			);
		},
		/**
		 * @desc: 根据指定层级获取菜单列表
		 * @param {Number} level 层级
		 * @param {Array} list 列表
		 * @param {Array} result 菜单列表
		 */
		getMenuByLevel(level, list, result = []) {
			if (isNaN(level) || level < 2) {
				return [];
			}
			if (!list || !Array.isArray(list)) {
				return [];
			}
			list.forEach(ele => {
				if (ele.level === level && (ele.parent_id === this.currentMenu.app_origin_id || ele.parent_id === this.currentMenu.id)) {
					result.push(ele);
				}
				if (ele.level < level) {
					const children_list = ele.children_list;
					if (children_list && Array.isArray(children_list) && children_list.length) {
						result = this.getMenuByLevel(level, children_list, result)
					}
				}
			});
			return result;
		},
		/**
		 * @desc: 查找当前所处的菜单组
		 */
		findCurrentMenu() {
			if (!this.appList) return null;
			const path = this.$route.path;
			for (let i = 0, len = this.appList.length; i < len; i++) {
				const { app_orign_data } = this.appList[i];
				const menuList = app_orign_data?.[0]?.children_list || [];
				for (let j = 0; j < menuList.length; j++) {
					// 顶部
					const item = menuList[j];
					const level2Children = item.children_list || [];
					// 第三级别
					if (level2Children?.find(ele => ele.menu_path === path)) {
						return item;
					}
					for (let k = 0; k < level2Children.length; k++) {
						if (level2Children[k]?.menu_type === 2 && level2Children[k]?.children_list.length) {
							// 第四级
							const level3Children = level2Children[k].children_list || [];
							if (level3Children?.find(ele => ele.menu_path === path)) {
								return item;
							}
							// 第五级别
							for (let l = 0; l < level3Children.length; l++) {
								if (level3Children[l].menu_type === 2 && level3Children[l].children_list.length) {
									const level4Children = level3Children[l].children_list || [];
									if (level4Children.find(ele => ele.menu_path === path)) {
										return item;
									}
								}
							}
						}
					}
				}
				return null;
			}
		},
		/**
		 * @desc: 根据当前的路由获取是顶部二级菜单
		 */
		setCurrentPart() {
			const path = this.$route.path;
			for (let i = 0, len = this.menuList.length; i < len; i++) {
				const { menu_path, children_list = [] } = this.menuList[i];
				if (menu_path === path) {
					this.onMenu(this.menuList[i])
					return;
				}
				// 四五级
				for (let j = 0, childrenLen = children_list.length; j < childrenLen; j++) {
					const child = children_list[j];
					if (child.menu_path === path || child.children_list.find(ele => ele.menu_path === path)) {
						this.onMenu(this.menuList[i])
						return;
					}
				}
			}
		},
		/**
		 * @desc: 右击菜单
		 * @param {Object} data
		 * @param {Object} e
		 */
		onContextMenu(data, e) {
			e.stopPropagation();
			e.preventDefault();
			console.log(data, '---右击菜单--', e)
			// const top = e.pageY;
			// const left = e.pageX;
			// this.$store.commit('showCustomMenu', {
			// 	position: {
			// 		left,
			// 		top
			// 	},
			// 	data
			// });
		},
		/**
		 * @desc: 滚动
		 */
		onScroll() {
			this.$store.commit('hideCustomMenu');
		}
  }
};
</script>

<style lang="less">
.menu-part{
	width: 64px;
	height: 100%;
	background: #f2f4f8;
	display: flex;
	flex-direction: column;
	overflow: hidden;
  box-shadow: 3px 0px 6px -2px rgba(0, 0, 0, 8%);
	user-select: none;
  .icon-box{
		height: 50px;
		line-height: 16px;
		font-size: 16px;
		cursor: pointer;
		box-sizing: border-box;
		padding: 17px 0;
		text-align: center;
  }
  .nav-one-list {
		// width: calc(100% + 15px);
		width: 100%;
		flex: 1;
		overflow: hidden;
		overflow-y: auto;
    .menu-item{
			position: relative;
			width: 64px;
			min-height: 64px;
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			justify-content: space-between;
			padding:5px;
			cursor: pointer;
			margin-bottom: 10px;
			margin: 0;
    }
  }
}
.menu-part-two {
	background:#40C7EC!important;
  width: 56px;
	.icon-box {
		color: #fff;
		background: linear-gradient(180deg, #40C7EC 0%, #1E95D4 100%) !important;
	}
	.nav-one-list {
		.menu-item {
      display: flex;
      width: 56px;
      // height: 58px;
      padding: 10px 8px 8px;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      flex-shrink: 0;
      box-sizing:border-box;
			// background: linear-gradient(180deg, #40C7EC 0%, #1E95D4 100%)!important;
      background: linear-gradient(180deg, #11C6F9 0%, #1E95D4 100%);
			color: #fff;
      min-height: 56px;
			&.active {
				opacity: 1;
        background: #fff;
				i{
					background: #fff;
          background: linear-gradient(180deg, #11C6F9 0%, #1E95D4 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
				}
				strong{
					// color: #fff;
          background: linear-gradient(180deg, #11C6F9 0%, #1E95D4 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          zoom: 0.83;
				}
        &:hover{
          background: #fff;
          .cover-bg{
            display: none;
          }
        }
			}
			&:hover{
				background: rgba(0, 0, 0, 0.1);
				.cover-bg{
					display: block;
				}
			}
			.cover-bg{
				position: absolute;
				display: none;
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				background: rgba(0, 0, 0, 0.05);
			}
			i, strong{
				width: 100%;
				color: #fff;
				font-weight: 400;
				text-align: center;
			}
			i {
				height: 24px;
				width: 24px;
				margin: 0 auto;
				line-height: 22px;
				font-size: 24px;
				// padding: 0 5px;
				box-sizing: border-box;
				border-radius: 4px;
				font-family: PingFangSC, PingFangSC-Regular;
			}
			strong {
				font-size: 12px;
				line-height: 20px;
        
        zoom: 0.83;
				font-family: PingFangSC, PingFangSC-Regular;
			}
		}
	}
}
.menu-part-one {
	.icon-box {
		color: #8d8d8d;
	}
	.nav-one-list {
		.menu-item {
			&.active {
				background: #fff;
				opacity: 1;
				i, strong{
					color: #409EFF;
				}
			}
			i,strong{
				width: 100%;
				color: #202126;
				font-weight: 400;
				text-align: center;
			}
			i {
				font-size: 18px;
				font-family: PingFangSC, PingFangSC-Regular;
			}
			strong {
				margin-top: 5px;
				font-size: 12px;
				line-height: 20px;
				font-family: PingFangSC, PingFangSC-Regular;
			}
		}
	}
}
</style>