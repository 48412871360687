<!--
 * @Description: 自定义右键菜单
 * @Author: luocheng
 * @Date: 2022-06-17 10:06:04
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-17 14:52:18
-->
<template>
  <div class="context-menu" v-show="showCustomMenu" :style="menuStyle">
    <ul @mouseup="onMouseUp">
			<li @click="onSetShortcut">设为快捷操作</li>
		</ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'ContextMenu',
  data() {
    return {
			windowHeight: 500
		}
  },
	computed: {
		...mapState([
			'showCustomMenu', //是否显示
			'customMenuPosition', // 位置
			'customMenuData', // 设为快捷方式的菜单数据
			'currentApp', // 当前应用
		]),
		// 菜单样式
		menuStyle() {
			const { left, top } = this.customMenuPosition
			let styleTop = top;
			if (top > this.windowHeight - 60) {
				styleTop = top - 50;
			}
			return {
				left: left + 'px',
				top: styleTop + 'px'
			}
		}
	},
	mounted() {
		this.getWindowHeight();
		window.onresize = () => {
			this.getWindowHeight();
		}
	},
  methods: {
		/**
		 * @desc: 获取窗口高度
		 */
		getWindowHeight() {
			this.windowHeight = window.innerHeight;
		},
    /**
     * @desc: 抬起鼠标
     */
    onMouseUp() {
      console.log('抬起鼠标-----抬起鼠标');
    },
		/**
		 * @desc: 快捷方式
		 */
		onSetShortcut() {
			console.log('-----快捷方式----', this.customMenuData)
			this.$confirm(`是否确认将菜单【${this.customMenuData?.name}】添加到快捷方式？`, '提示').then(() => {
				console.log('000确认------');
				// 当前应用
				const app = this.currentApp;
				console.log(app, '----app----')
				this.$store.commit('setMenuAppData', {
					app,
					data: this.customMenuData
				});
			}).catch(() => {});
		}
  }
}
</script>

<style lang="less" scoped>
// @hoverColor: @theme;
@hoverColor: #40C7EC;
.context-menu{
  position: absolute;
	z-index: 100000;
	ul {
		border: 1px solid #e4e7ed;
		border-radius: 4px;
		background-color: #fff;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
		margin: 5px 0;
		padding: 6px 0;
		li {
			font-size: 14px;
			padding: 0 20px;
			position: relative;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: #606266;
			height: 34px;
			line-height: 34px;
			box-sizing: border-box;
			cursor: pointer;
			font-weight: 500;
			&:hover {
				background-color: #f5f7fa;
        color: @hoverColor;
			}
			&.disable-btn{
				color: @disableColor;
				cursor: not-allowed;
			}
			&.remove{
				color: @dangerColor;
			}
		}
	}
}
</style>