var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"child-nav child-nav-cv 123",class:[_vm.themeClassName],style:({
			width: _vm.showMenuList && _vm.menuList.length ? '180px' : '0px',
			padding: _vm.showMenuList && _vm.menuList.length ? '0px' : '0px'
		})},[_c('header',{staticClass:"child-header"},[_c('div',{staticClass:"name"},[_c('img',{attrs:{"src":require("@/assets/images/menu-icon.png"),"alt":""}}),(_vm.menuName && _vm.menuName.length > 6)?_c('el-tooltip',{staticClass:"item 22222",attrs:{"content":_vm.menuName,"placement":"top"}},[_c('h4',{staticClass:"hidden-part"},[_vm._v(_vm._s(_vm.menuName))])]):_c('h4',[_vm._v(_vm._s(_vm.menuName))])],1)]),_c('article',{staticClass:"menu-box",on:{"scroll":_vm.closeMenu}},[(_vm.menuList && _vm.menuList.length)?_c('div',{staticClass:"menu-list"},[_c('el-menu',{staticClass:"menu-main",attrs:{"default-active":"","default-openeds":_vm.defaultOpened}},_vm._l((_vm.menuList),function(item){return (item.menu_type === 2
							&& !item.is_hide
							&& _vm.judgingArchiAPermission(item.archi_limit, item.permissions)
							&& _vm.judgingCustomLogic(item.custom_logics)
						)?_c('el-submenu',{key:item.id,attrs:{"index":item.menu_path}},[_c('template',{slot:"title"},[(item.icon && item.icon.toString().indexOf('el-icon') === 0)?_c('i',{staticClass:"group-icon",class:item.icon}):_c('i',{staticClass:"group-icon",class:['iconfont', item.icon || 'iconyeqian']}),_c('span',{staticClass:"group-title",attrs:{"title":item?.name?.length >= 8 ? item.name : ''}},[_vm._v(_vm._s(item.name))])]),_vm._l((item.children_list),function(child){return _c('span',{key:child.id},[(!child.is_hide
									&& _vm.judgingArchiAPermission(child.archi_limit, child.permissions)
									&& _vm.judgingCustomLogic(child.custom_logics)
								)?_c('el-menu-item',{class:{
									'is-active': _vm.isCurrentActive(child),
									'without-active': !_vm.isCurrentActive(child)
								},attrs:{"index":child.menu_path || child.link,"data-config":JSON.stringify(child)},on:{"click":function($event){return _vm.onItem(child)},"contextmenu":function($event){return _vm.onContextMenu(child, $event)}}},[_c('template',{slot:"title"},[(child.icon && child.icon.toString().indexOf('el-icon') === 0)?_c('i',{class:['item-icon', child.icon]}):_c('i',{class:['item-icon', 'iconfont', child.icon || 'iconyeqian']}),_c('span',{attrs:{"title":child?.name?.length >= 4 ? child.name : ''}},[_vm._v(_vm._s(child.name))])])],2):_vm._e()],1)})],2):(!item.is_hide
							&& _vm.judgingArchiAPermission(item.archi_limit, item.permissions)
							&& _vm.judgingCustomLogic(item.custom_logics)
						)?_c('el-menu-item',{class:{
							'is-active': _vm.isCurrentActive(item),
							'without-active': !_vm.isCurrentActive(item)
						},attrs:{"index":item.menu_path || item.link,"data-config":JSON.stringify(item)},on:{"click":function($event){return _vm.onItem(item)},"contextmenu":function($event){return _vm.onContextMenu(item, $event)}}},[(item.icon && item.icon.toString().indexOf('el-icon') === 0)?_c('i',{class:['item-icon', item.icon]}):_c('i',{class:['item-icon', 'iconfont', item.icon || 'iconyeqian']}),_c('span',{attrs:{"slot":"title","title":item?.name?.length >= 4 ? item.name : ''},slot:"title"},[_vm._v(_vm._s(item.name))])]):_vm._e()}),1)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }