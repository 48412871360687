<!--
 * @Description: 基座外层框架的index
 * @Author: luocheng
 * @Date: 2021-11-02 09:53:57
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-11-30 14:38:41
-->
<template>
	<div class="pedestal-entry app-entry">
		<el-header
			class="pedestal-header"
			style="height: auto;"
		>
			<TopBar></TopBar>
		</el-header>
		<el-container class="pedestal-container">
			<el-aside style="width: auto;" v-show="!isFullScreen && !isUpdatePwd">
				<NavBar />
			</el-aside>
			<el-main class="main">
				<!-- 历史记录 -->
				<NavHistory
					v-if="systemConfig?.theme_append_info?.needHistory"
					v-show="!isFullScreen">
				</NavHistory>
				<!-- 内容区域 -->
				<div class="content-box" :style="contentBoxStyle">
					<div class="content-box-bg" 
            v-loading="showMainLoading"
            element-loading-text="资源加载中，请稍后..."
            element-loading-spinner="el-icon-loading"
						:style="{
							'padding': hidePadding ? 0 : '16px'
						}"
          >
						<div class="pedestal-content" :style="pedestalStyleObj">
							<!-- 子应用 -->
								<!-- style="min-height: 100%;width:100%;overflow-y:auto" -->
								<!-- v-if="showContainer" -->
							<div
								id="container"
								style="height: 100%;width:100%;overflow-y:auto"
								v-show="showContainer"
							></div>
							<!-- 常规路由 -->
							<div v-show="!showContainer" style="height: 100%;width: 100%">
								<!-- 需要缓存的路由  中控台 -->
								<!-- <keep-alive>
									<router-view v-show="$route.meta.keepAlive"></router-view>
								</keep-alive>
								<router-view v-if="!$route.meta.keepAlive"></router-view> -->
								<router-view></router-view>
							</div>
						</div>
					</div>
				</div>
			</el-main>
		</el-container>
		<!-- 自定义菜单 -->
		<ContextMenu v-if="false"></ContextMenu>
	</div>
</template>

<script>
import { Aside, Container, Header, Main } from 'element-ui';
// import TopBar from './components/TopBar';
// import NavBar from './components/NavBar';
import { start } from 'qiankun';
import { mapState } from 'vuex';
import ContextMenu from './components/ContextMenu.vue';
import eventBus from '@/plugins/eventBus';
import NavHistory from './components/NavHistory';
import TopBar from './components/top/Index';
import NavBar from './components/side/Index';

export default {
	name: 'Entry',
	components: {
		'el-container': Container,
		'el-header': Header,
		'el-aside': Aside,
		'el-main': Main,
		TopBar,
		NavBar,
		ContextMenu,
		NavHistory
	},
	data() {
		return {
			showContainer: false,
			showMenu: false, // 显示菜单
      showMainLoading : false, // 中间主要部分(主要是给微应用加载时)的加载动画
			isUpdatePwd: true,
			hidePadding: false
		};
	},
	computed: {
		...mapState([
			'currentApp',
			'currentMenu',
			'systemConfig'
		]),
		// 根据当前点击的顶部菜单判断是否内容区全屏显示
		isFullScreen() {
			const originData = this.currentApp?.app_orign_data || [];
			if (!Array.isArray(originData) || !originData.length) return false;
			if (+originData[0].is_screen === 1) {
				return true;
			}
			const childrenList = originData[0].children_list || [];
			const childrenLevelOne = childrenList.filter(ele => +ele.level === 1);
			let currentMenuLevel = childrenLevelOne.find(ele => ele.id === this.currentMenu?.id);
			if (currentMenuLevel) {
				return !!currentMenuLevel?.is_screen;
			}
			// 顶部二级菜单的直接层次
			const childrenLevelTwo = childrenList.filter(ele => +ele.level === 2);
			currentMenuLevel = childrenLevelTwo.find(ele => ele.id === this.currentMenu?.id);
			if (currentMenuLevel) {
				return !!currentMenuLevel?.is_screen;
			}
			return false;
		},
		// 内容样式对象
		contentBoxStyle() {
			const { theme_type = 1 } = this.systemConfig || {};
			if (theme_type === 2) {
				return {
					height: this.isFullScreen ? 'calc(100vh - 104px)' : '100%'
				}
			}
			// 新主题带历史记录
			// console.log(this.systemConfig?.theme_append_info, '---this.systemConfig?.theme_append_info----')
			if (theme_type === 3 && this.systemConfig?.theme_append_info?.needHistory) {
				if (this.isFullScreen) {
					return {
						height: 'calc(100vh - 48px)'
					}
				}
				return {
					height: 'calc(100vh - 95px)'
				}
			}
			return {
				height: 'calc(100vh - 48px)'
			}
		},
		pedestalStyleObj() {
			const { theme_type = 1 } = this.systemConfig || {};
			if (theme_type === 2) {
				return {
					height: this.isFullScreen ? 'calc(100vh - 136px)' : '100%'
				}
			}
			// 新主题带历史记录
			if (theme_type === 3 && this.systemConfig?.theme_append_info?.needHistory) {
				if (this.isFullScreen) {
					if (this.hidePadding) {
						return {
							height:'calc(100vh - 48px)'
						}
					}
					return {
						height: 'calc(100vh - 80px)'
					}
				}
				return {
					height: 'calc(100vh - 127px)'
				}
			}
			// 无边框
			if (this.hidePadding) {
				return {
					height:'calc(100vh - 48px)'
				}
			}
			return {
				height:'calc(100vh - 80px)'
			}
		}
	},
	created() {
		this.hidePadding = !!(+this.$route?.query?.hidePadding === 1);
		this.isUpdatePwd = localStorage.getItem('updatePwd') == '0' && this.systemConfig.force_password;
	},
	watch: {
		$route: {
			handler() {
				// 显示微应用容器，以各种微应用特定前缀开头
				// console.log(this.$route.path.indexOf('/child-app-ts/'), '------1111----',	this.$route.path, '2222222', this.$router, '----333----', this.$router.path
				// )
				this.hidePadding = !!(+this.$route?.query?.hidePadding === 1);
				this.showContainer = !!(
					this.$route.path.includes('/child-app/') ||
					this.$route.path.includes('/child-app-bim/') ||
					this.$route.path.includes('/child-app-ts/') ||
					this.$route.path.includes('/app-vue/')
				);
				// console.log(this.showContainer, '---showContainer')
				// 切换时候关闭loading
				this.showMainLoading = false;
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.$nextTick(() => {
			start({
				singular: false, 
				sandbox: true,
				// sandbox: {
				// 	strictStyleIsolation: true,
				// 	experimentalStyleIsolation: true
				// },
				prefetch: false
			});
		});
    eventBus.$on('MicroAppLoading', (show) => {
      console.log(show,'micro app loading ')
      this.$nextTick(() => {
        if(!show){
          setTimeout(() => {
            this.showMainLoading = false
          }, 200);
        }else{
          this.showMainLoading = show;
        }
      })
    });
	}
};
</script>

<style lang="less" scoped>
@headerHeight: 48px;
.pedestal-entry {
	height: 100%;
	width: 100%;
	min-width: 860px;
	min-height: 600px;
	font-family: PingFangSC, PingFangSC-Medium, Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	display: flex;
	flex-direction: column;
	.pedestal-header {
		width: 100%;
		background: #0f62fe;
		box-sizing: border-box;
		padding: 0;
	}
	.pedestal-container {
		flex: 1;
		width: 100%;
		display: flex;
		overflow: hidden;
		.el-aside {
			position: relative;
			width: auto;
			box-shadow: 3px 0px 6px -2px rgba(0, 0, 0, 0.08);
			z-index: 10;
		}
		.el-main {
			flex: 1;
			overflow: hidden;
			box-sizing: border-box;
			padding: 0;
			position: relative;
			z-index: 10;
			background: transparent;
			.content-box {
				box-sizing: border-box;
				width: 100%;
				flex: 1;
				background: #f2f4f8;
				overflow: hidden;
				overflow-y: auto;
				&.content-box-one{
					height: calc(100% - 50px);
				}
				&.content-box-two{
					height: calc(100vh - 98px);
				}
				.content-box-bg {
					height: 100%;
					width: 100%;
					box-sizing: border-box;
					padding: 16px;
					.pedestal-content {
						box-sizing: border-box;
						width: 100%;
						height: 100%;
						overflow: hidden;
						background: #fff;
						border-radius: 4px;
						// 暂时隐藏
						// box-shadow: 0px 0px 20px 0px rgba(72, 134, 255, 0.1);
						>#container{
							>div{
								height: 100%;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}
#container {
	>div {
		height: 100%;
		width: 100%;
	}
}
</style>
