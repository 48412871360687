<!--
 * @Description: 顶部
 * @Author: luocheng
 * @Date: 2022-07-13 15:22:07
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-02-16 14:04:40
-->
<template>
  <div class="top-bar" v-if="systemConfig">
    <!-- 类南华 -->
    <ThemeOne v-if="themeType === 1"></ThemeOne>
    <!-- 类似罗铁 -->
    <ThemeTwo v-else-if="themeType === 2"></ThemeTwo>
    <!-- 新版本顶部主题 -->
    <ThemeThree v-else-if="themeType === 3"></ThemeThree>
  </div>
</template>

<script>
import ThemeOne from './ThemeOne';
import ThemeTwo from './ThemeTwo';
import ThemeThree from './ThemeThree';
import { mapState } from 'vuex';

export default {
  name: 'TopBar',
  components: {
    ThemeOne,
    ThemeTwo,
    ThemeThree
  },
  computed: {
    ...mapState(['systemConfig']),
    themeType() {
      return this.systemConfig.theme_type || 1;
    }
  }
}
</script>

<style lang="less" scoped>
.top-bar{
  height: auto;
  width: 100%;
}
</style>